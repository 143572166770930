.App {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-banner {
  height: 5vmin;
  pointer-events: none;
  stroke: $theme-ecs-clr-transparent-alt;
  fill: $theme-ecs-clr-transparent-alt;
}

.App-header {
  background-color: $theme-ecs-background;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $theme-ecs-clr-dark;
}

.App-buttons {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.App-logout {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10000;
}

.table {
  background: white;
}

.table td {
  border: 1px solid #ccc;
  color: black;
  font-size: 14px;
  padding: 10px;
}

.table th {
  color: black;
  border: 1px solid #ccc;
  font-weight: bold;
  font-size: 14px;
  font-family: tahoma;
  padding: 10px;
}